import React from 'react';
import { Person as PersonType } from '../types';

interface PersonProps {
  person: PersonType;
  onNameChange: (name: string) => void;
  onBirthdateChange: (birthdate: string) => void;
}

const Person: React.FC<PersonProps> = ({ person, onNameChange, onBirthdateChange }) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onNameChange(e.target.value);
  };

  const handleBirthdateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBirthdateChange(e.target.value);
  };

  return (
    <div className="card w-96 bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">
          <input
            type="text"
            value={person.name}
            onChange={handleNameChange}
            className="input w-full p-0"
          />
        </h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-base-content">{person.name}'s Birthdate</label>
          <input
            type="date"
            value={person.birthdate}
            onChange={handleBirthdateChange}
            className="input input-bordered w-full mt-1"
          />
        </div>
      </div>
    </div>
  );
};

export default Person;
