import React from 'react';

const Layout: React.FC<{ children: React.ReactNode }> = ({children}) => {

    const [theme, setTheme] = React.useState('light');
    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };
    React.useEffect(() => {
        if (document && document.querySelector('html')) {
            document.querySelector('html')!.setAttribute('data-theme', theme);
        }
    }, [theme]);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia(
            "(prefers-color-scheme: dark)"
        );
      
        if (mediaQuery.matches) {
            setTheme('dark');
        }
      
        // This callback will fire if the perferred color scheme changes without a reload
        mediaQuery.addEventListener("change", (evt) => setTheme(evt.matches ? "dark" : "light"));
      }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <div className="navbar bg-base-100 sm:flex-row flex-col">
                <div className="flex-1">
                    <div className="text-4xl sm:text-xl">Double age calculator</div>
                </div>
                <label className="label flex flex-row space-x-2 max-sm:mt-4">
                    <span className="label-text">Dark mode</span> 
                    <input type="checkbox" className="toggle max-sm:toggle-lg" onClick={toggleTheme} checked={theme === 'dark'} />
                </label>
            </div>

            <main className="flex-grow flex items-center justify-center">
                {children}
            </main>

            <footer className="footer footer-center p-4">
                <div className="">
                    <p>2024 made by Oliver Hilsky</p>
                    <div className="space-x-6">
                        <a href="/welcome/impressum.html">Impressum / Legal</a>
                        <a href="/welcome/privacy-policy.html">Datenschutzerklärung / Privacy policy</a>
                    </div>
                </div>
            </footer>
        </div>
      );
}

export default Layout;