export const DEFAULTS = {
    PEOPLE: [
      { name: "Bill Gates", birthdate: "1955-10-28" },
      { name: "Steve Jobs", birthdate: "1955-02-24" },
      { name: "Barack Obama", birthdate: "1961-08-04" },
      { name: "Oprah Winfrey", birthdate: "1954-01-29" },
      { name: "Jeff Bezos", birthdate: "1964-01-12" },
      { name: "Elon Musk", birthdate: "1971-06-28" },
      { name: "Angela Merkel", birthdate: "1954-07-17" },
      { name: "Michael Jordan", birthdate: "1963-02-17" },
      { name: "Madonna", birthdate: "1958-08-16" },
      { name: "Lady Gaga", birthdate: "1986-03-28" },
      { name: "Serena Williams", birthdate: "1981-09-26" },
      { name: "J.K. Rowling", birthdate: "1965-07-31" },
      { name: "Tiger Woods", birthdate: "1975-12-30" },
      { name: "Mark Zuckerberg", birthdate: "1984-05-14" },
      { name: "Jennifer Lopez", birthdate: "1969-07-24" },
      { name: "Shakira", birthdate: "1977-02-02" },
      { name: "Leonardo DiCaprio", birthdate: "1974-11-11" },
      { name: "Cristiano Ronaldo", birthdate: "1985-02-05" },
      { name: "Emma Watson", birthdate: "1990-04-15" },
      { name: "Taylor Swift", birthdate: "1989-12-13" },
      { name: "Beyoncé", birthdate: "1981-09-04" },
      { name: "Rihanna", birthdate: "1988-02-20" },
      { name: "Tom Holland", birthdate: "1996-06-01" },
      { name: "Zendaya", birthdate: "1996-09-01" },
      { name: "Chris Hemsworth", birthdate: "1983-08-11" },
      { name: "Scarlett Johansson", birthdate: "1984-11-22" },
      { name: "Gal Gadot", birthdate: "1985-04-30" },
      { name: "Dwayne Johnson", birthdate: "1972-05-02" },
      { name: "Ariana Grande", birthdate: "1993-06-26" },
      { name: "Bruno Mars", birthdate: "1985-10-08" },
      { name: "Miley Cyrus", birthdate: "1992-11-23" },
      { name: "Katy Perry", birthdate: "1984-10-25" },
      { name: "Justin Bieber", birthdate: "1994-03-01" },
      { name: "Shawn Mendes", birthdate: "1998-08-08" },
      { name: "Ed Sheeran", birthdate: "1991-02-17" },
      { name: "Selena Gomez", birthdate: "1992-07-22" },
      { name: "Kanye West", birthdate: "1977-06-08" },
      { name: "Kim Kardashian", birthdate: "1980-10-21" },
      { name: "Kylie Jenner", birthdate: "1997-08-10" },
      { name: "Gigi Hadid", birthdate: "1995-04-23" },
      { name: "Zayn Malik", birthdate: "1993-01-12" },
      { name: "Harry Styles", birthdate: "1994-02-01" },
      { name: "Niall Horan", birthdate: "1993-09-13" },
      { name: "Liam Payne", birthdate: "1993-08-29" },
      { name: "Louis Tomlinson", birthdate: "1991-12-24" },
      { name: "Camila Cabello", birthdate: "1997-03-03" },
      { name: "Billie Eilish", birthdate: "2001-12-18" },
      { name: "Shawn Mendes", birthdate: "1998-08-08" },
      { name: "Dua Lipa", birthdate: "1995-08-22" },
      { name: "Sophie Turner", birthdate: "1996-02-21" },
      { name: "Joe Jonas", birthdate: "1989-08-15" },
      { name: "Nick Jonas", birthdate: "1992-09-16" },
      { name: "Priyanka Chopra", birthdate: "1982-07-18" },
      { name: "Maisie Williams", birthdate: "1997-04-15" },
      { name: "Millie Bobby Brown", birthdate: "2004-02-19" },
      { name: "Finn Wolfhard", birthdate: "2002-12-23" },
      { name: "Noah Schnapp", birthdate: "2004-10-03" },
      { name: "Sadie Sink", birthdate: "2002-04-16" },
      { name: "Gaten Matarazzo", birthdate: "2002-09-08" },
      { name: "Caleb McLaughlin", birthdate: "2001-10-13" },
      { name: "David Harbour", birthdate: "1975-04-10" },
      { name: "Winona Ryder", birthdate: "1971-10-29" },
      { name: "Natalia Dyer", birthdate: "1995-01-13" },
      { name: "Charlie Heaton", birthdate: "1994-02-06" },
      { name: "Kiernan Shipka", birthdate: "1999-11-10" },
      { name: "Ross Lynch", birthdate: "1995-12-29" },
      { name: "Lucy Hale", birthdate: "1989-06-14" },
      { name: "Ashley Benson", birthdate: "1989-12-18" },
      { name: "Shay Mitchell", birthdate: "1987-04-10" },
      { name: "Troian Bellisario", birthdate: "1985-10-28" },
      { name: "Dylan O'Brien", birthdate: "1991-08-26" },
      { name: "Tyler Posey", birthdate: "1991-10-18" },
      { name: "Holland Roden", birthdate: "1986-10-07" },
      { name: "Colton Haynes", birthdate: "1988-07-13" },
      { name: "Crystal Reed", birthdate: "1985-02-06" },
      { name: "Zendaya", birthdate: "1996-09-01" },
      { name: "Jacob Elordi", birthdate: "1997-06-26" }
    ]
  };
  