import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Person from './Person';
import Result from './Result';
import { Person as PersonType } from '../types';
import { DEFAULTS } from '../config';

const getRandomPeople = () => {
  const shuffled = DEFAULTS.PEOPLE.sort(() => 0.5 - Math.random());
  return [shuffled[0], shuffled[1]];
};

const DoubleAgeCalculator: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [randomPersonOne, randomPersonTwo] = getRandomPeople();

  const getInitialPersonState = (nameParam: string, birthParam: string, defaultName: string, defaultBirthdate: string): PersonType => {
    return {
      name: searchParams.get(nameParam) || defaultName,
      birthdate: searchParams.get(birthParam) || defaultBirthdate,
    };
  };

  const [personOne, setPersonOne] = useState<PersonType>(
    getInitialPersonState('p1n', 'p1birth', randomPersonOne.name, randomPersonOne.birthdate)
  );
  const [personTwo, setPersonTwo] = useState<PersonType>(
    getInitialPersonState('p2n', 'p2birth', randomPersonTwo.name, randomPersonTwo.birthdate)
  );
  const [doubleAgeDate, setDoubleAgeDate] = useState<Date | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const calculateAge = (birthdate: string): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getDoubleAgeDate = (olderBirthdate: string, youngerBirthdate: string): Date | null => {
    const olderBirthDate = new Date(olderBirthdate);
    const youngerBirthDate = new Date(youngerBirthdate);
    const ageDifference = youngerBirthDate.getTime() - olderBirthDate.getTime();
    
    if (ageDifference <= 0) {
      return null;
    }

    const doubleAgeDate = new Date(olderBirthDate.getTime() + 2 * ageDifference);
    return doubleAgeDate;
  };

  const calculateDoubleAge = useCallback(() => {
    const ageOne = calculateAge(personOne.birthdate);
    const ageTwo = calculateAge(personTwo.birthdate);

    let older = personOne;
    let younger = personTwo;
    if (ageOne < ageTwo) {
      [older, younger] = [personTwo, personOne];
    }

    const doubleAgeDate = getDoubleAgeDate(older.birthdate, younger.birthdate);

    if (doubleAgeDate) {
      setDoubleAgeDate(doubleAgeDate);
    } else {
      setDoubleAgeDate(null);
    }
  }, [personOne, personTwo]);

  useEffect(() => {
    if (personOne.birthdate && personTwo.birthdate) {
      calculateDoubleAge();
      // Remove URL parameters after initial load
      navigate('/', { replace: true });
    }
  }, [personOne.birthdate, personTwo.birthdate, calculateDoubleAge, navigate]);

  const generatePersonUrlParams = (person: PersonType, prefix: string) => {
    return `${prefix}n=${encodeURIComponent(person.name)}&${prefix}birth=${person.birthdate}`;
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}${window.location.pathname}?${generatePersonUrlParams(personOne, 'p1')}&${generatePersonUrlParams(personTwo, 'p2')}`;
    navigator.clipboard.writeText(link)
      .then(() => {
        setToastMessage('Link copied to clipboard');
        setTimeout(() => setToastMessage(null), 2000);
      })
      .catch(err => console.error('Failed to copy link: ', err));
  };

  const ageOne = calculateAge(personOne.birthdate);
  const ageTwo = calculateAge(personTwo.birthdate);

  const olderPerson = ageOne > ageTwo ? personOne : personTwo;
  const youngerPerson = ageOne > ageTwo ? personTwo : personOne;

  return (
    <div className="flex flex-col items-center space-y-6">
      <h1 className="text-2xl font-bold mt-6 mx-4">When is one person twice as old as the other?</h1>
      <div className="text-lg text-center mb-6">
        <p>
          At what time is {olderPerson.name} double the age of {youngerPerson.name}?
        </p>
        <p>
          Provide the birthdates and find out.
        </p>
      </div>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <Person 
          person={personOne}
          onNameChange={(name) => setPersonOne({ ...personOne, name })}
          onBirthdateChange={(birthdate) => setPersonOne({ ...personOne, birthdate })}
        />
        <Person 
          person={personTwo}
          onNameChange={(name) => setPersonTwo({ ...personTwo, name })}
          onBirthdateChange={(birthdate) => setPersonTwo({ ...personTwo, birthdate })}
        />
      </div>
      <div className="divider">AGE</div>
      <Result
        doubleAgeDate={doubleAgeDate}
        olderPerson={olderPerson}
        youngerPerson={youngerPerson}
      />

      <div className='w-full flex flex-col items-center'>
        <button className="btn btn-circle shadow-xl" onClick={handleCopyLink}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.68439 10.6578L15.3124 7.34378M15.3156 16.6578L8.69379 13.3469M21 6C21 7.65685 19.6569 9 18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6ZM9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12ZM21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z" stroke-width="1.5"></path> </g></svg>
        </button>
      </div>

      {toastMessage && (
        <div className="toast toast-end">
          <div className="alert alert-info">
            <span>{toastMessage}</span>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default DoubleAgeCalculator;
