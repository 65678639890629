import React from 'react';
import './App.css';
import DoubleAgeCalculator from './components/DoubleAgeCalculator';
import Layout from './components/Layout';
import { BrowserRouter } from 'react-router-dom';
const { homepage } = require('../package.json');

const App: React.FC = () => {
  return (
    <BrowserRouter basename={homepage}>
      <Layout>
        <DoubleAgeCalculator />
      </Layout>
    </BrowserRouter>
  );
};

export default App;
