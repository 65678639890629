import React from 'react';

interface ResultProps {
  doubleAgeDate: Date | null;
  olderPerson: { name: string, birthdate: string };
  youngerPerson: { name: string, birthdate: string };
}

const Result: React.FC<ResultProps> = ({ doubleAgeDate, olderPerson, youngerPerson }) => {
  const calculateAgeInYears = (birthdate: string, referenceDate: Date): number => {
    const birthDate = new Date(birthdate);
    let age = referenceDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = referenceDate.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && referenceDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateAgeInDays = (birthdate: string, referenceDate: Date): number => {
    const birthDate = new Date(birthdate);
    const timeDiff = referenceDate.getTime() - birthDate.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  if (!doubleAgeDate) {
    return <p className="text-red-500">Failed to calculate. Please ensure two valid different birthdates are entered.</p>;
  }

  const yearsYounger = calculateAgeInYears(youngerPerson.birthdate, doubleAgeDate);
  const yearsOlder = calculateAgeInYears(olderPerson.birthdate, doubleAgeDate);
  const daysYounger = calculateAgeInDays(youngerPerson.birthdate, doubleAgeDate);
  const daysOlder = calculateAgeInDays(olderPerson.birthdate, doubleAgeDate);
  const now = new Date();
  const isPast = doubleAgeDate < now;

  return (
    <div className="card w-96 bg-base-100 shadow-xl">
      <div className="card-body items-center text-center">
        <div className='mb-4'>
          <div className="">
            {olderPerson.name} {isPast ? 'was' : 'will be'} double the age of {youngerPerson.name} on
          </div>
          <div className="text-xl">
            {doubleAgeDate.toDateString()}.
          </div>
        </div>
        
        <div className='mb-1'>
          At that time:
        </div>
        <div className='mb-4'>
          <div>
            {youngerPerson.name} {isPast ? 'was' : 'will be'}
          </div>
          <div className='text-xl'>
            {yearsYounger} years ({daysYounger} days)
          </div>
        </div>
        
        <div className=''>
          <div>
            {olderPerson.name} {isPast ? 'was' : 'will be'}
          </div>
          <div className='text-xl'>
            {yearsOlder} years ({daysOlder} days) old.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
